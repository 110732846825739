import PropTypes from 'prop-types'
import React from 'react'

import { RichText } from '../html'
import './InTheNews.scss'

const InTheNews = ({ body }) => {
  if (!body) {
    return null
  }

  return (
    <div className='news-container'>
      <div><RichText render={body.primary.inTheNewsTitle} /></div>
      <div className='logos-container'>
        {body.items.map((item) => (
          <a href={item?.link?.url} target='_blank' rel='noopener noreferrer'>
            <img key={item.image.alt} src={item.image.fluid.src} alt={item.image.alt} />
          </a>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment InTheNewsFragment on PrismicPageBodyInTheNews {
    slice_type
    primary {
      inTheNewsTitle: title {
        html
        text
      }
    }
    items {
      link {
        url
        target
      }
      image {
        alt
        fluid {
          sizes
          src
          aspectRatio
          base64
          srcWebp
          srcSetWebp
        }
      }
    }
  }
`

InTheNews.propTypes = {
  body: PropTypes.object,
}

export default InTheNews
